<template>
  <b-button :type="type" :variant="variant" :disabled="isBusy" :size="size">
    <div class="d-flex align-items-center">
      <b-spinner v-if="isBusy" small type="grow" class="mr-2"/>
      {{ isBusy ? loadingText : text }}
    </div>
  </b-button>
</template>

<script>
export default {
  name: 'LoadingButton',
  props: {
    isBusy: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: 'Подождите',
    },
    type: {
      type: String,
      default: 'submit',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    text: {
      type: String,
      default: 'Сохранить',
    },
    size: {
      type: String,
    },
  },
  data() {
    return {}
  },
}
</script>

<style scoped>

</style>
