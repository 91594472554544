<template>
  <div>
    <b-row class="mt-1 mb-2">
      <b-col>
        <div class="d-flex">
          <b-button v-b-modal.modal-1 class="mb-1" @click="showFormDialog(null)">
            Добавить
          </b-button>
          <div class="ml-2 w-100">
            <b-input-group>
              <b-form-input v-model="searchString" placeholder="Поиск по названию промокода" class="search" />
              <b-input-group-append v-if="searchString">
                <b-button variant="outline-secondary" @click="searchString=''">
                  <b-icon icon="backspace-fill" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <b-modal id="modal-1" size="lg" :title="isNewPromoCode ? 'Создание промокода' : 'Редактирование промокода'" hide-footer @hidden="errorMessage=null">
          <b-form size="sm" @submit="savePromoCode">
            <b-form-group v-if="isNewPromoCode" label="ID:">
              <b-input v-model="form.id" required />
            </b-form-group>
            <b-form-group label="Название:">
              <multiselect
                v-model="form.titles"
                tag-placeholder="Нажмите сюда для сохранения"
                :options="promoNames"
                :multiple="true"
                :taggable="true"
                :allow-empty="false"
                @tag="addPromoName"
              >
                <span slot="noOptions">...</span>
              </multiselect>
            </b-form-group>
            <b-form-checkbox v-model="form.isActive" switch>
              Активен?
            </b-form-checkbox>


            <b-alert v-model="showErrorOnSave" dismissible variant="danger">
              <span v-html="errorMessage"></span>
            </b-alert>
            <div style="text-align: right;">
              <loading-button :is-busy="isBusy" :text="isNewPromoCode ? 'Создать':'Сохранить'" />
            </div>

          </b-form>
        </b-modal>
      </b-col>
    </b-row>
    <!-- table -->
    <div>
      <b-table
        ref="table"
        striped
        hover
        show-empty
        small
        :items="getPromoCodes"
        :fields="fields"
        :busy.sync="isBusy"
        empty-text="Нет промокодов"
        empty-filtered-text="Не найдено"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template  #cell(title)="row">
          {{ implodeTitles(row.item)}}
        </template>
        <template v-slot:cell(isActive)="data">
          <span v-if="data.value">Активен</span>
          <span v-else>Не активен</span>
        </template>
        <template  #cell(registrationCount)="row">
          <span v-html="linkToUsers(row)"></span>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button-group>
            <b-button variant="primary" size="sm" @click="showFormDialog(data.item)">
              <b-icon-pencil />
            </b-button>
            <b-button variant="danger" size="sm" @click="showConfirm(data.item.id)">
              <b-icon icon="trash-fill" aria-hidden="true" />
            </b-button>
          </b-button-group>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        align="center"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="my-table"
      />
      <b-modal
        id="modal-delete"
        title="Удаление промокода"
        @ok="deletePromoCode(form.id)"
      >
        <p>Вы действительно желаете удалить промокод?</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import LoadingButton from '../components/LoadingButton'
import Multiselect from 'vue-multiselect'

export default {
  name: 'PromoCodes',
  components: {LoadingButton, Multiselect},
  data() {
    return {
      searchString: '',
      isBusy: false,
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {key: 'id', label: '#'},
        {key: 'title', label: 'Название'},
        {key: 'isActive', label: 'Статус'},
        {key: 'createdAt', label: 'Дата создания'},
        {key: 'registrationCount', label: 'Регистрации'},
        {key: 'registrationPromoName', label: 'С промокодом'},
        {key: 'actions', label: 'Действия'},
      ],
      items: [],

      isNewPromoCode: true,
      form: {
        id: null,
        titles: [],
        isActive: true,
      },
      errorMessage: null,
      promoNames: [],
    }
  },
  computed:{
    showErrorOnSave(){
      return !!this.errorMessage
    },
  },
  watch: {
    searchString() {
      setTimeout(() => {
        this.$refs.table.refresh()
      }, 200)
    },
  },
  methods: {
    showFormDialog(item) {
      this.form = item ?? {id: null, titles: [], isActive: true}
      this.isNewPromoCode = !this.form.id
      this.$bvModal.show('modal-1')
    },
    showConfirm(id) {
      this.form.id = id
      this.$bvModal.show('modal-delete')
    },
    async getPromoCodes(ctx) {
      const promise = this.axios.get(process.env.VUE_APP_BACKEND_URL + '/admin/api/promocodes?search=' + this.searchString + '&page=' + ctx.currentPage + '&perpage=' +
        ctx.perPage, this.$store.getters.getAxiosConfig)
      return promise.then(response => {
        this.items = response.data.promocodes
        this.totalRows = response.data.totalRows
        return this.items || []
      }).catch((error) => {
        console.log(error)
        this.items = []
        this.totalRows = 0
        return this.items || []
      })
    },
    savePromoCode(evt) {
      evt.preventDefault()
      if (!this.nameValidate() || !this.idValidate())
        return

      let promise
      if (this.isNewPromoCode) {
        promise = this.axios.post(process.env.VUE_APP_BACKEND_URL + '/admin/api/promocodes', this.form, this.$store.getters.getAxiosConfig)
      } else {
        promise = this.axios.put(process.env.VUE_APP_BACKEND_URL + '/admin/api/promocodes', this.form, this.$store.getters.getAxiosConfig)
      }
      promise.then((response) => {
        if (response) {
          if (response.data.error === false) {
            this.$refs.table.refresh()
            this.errorMessage = null
            this.$bvModal.hide('modal-1')
          } else {
            this.errorMessage = response.data.message
          }
        }
      })
        .catch((error) => {
          console.log(error)
          this.errorMessage = error.response.data.message
        })
    },
    deletePromoCode(id) {
      this.loading = true
      this.axios.delete(process.env.VUE_APP_BACKEND_URL + '/admin/api/promocodes/' + id, this.$store.getters.getAxiosConfig)
        .then((response) => {
          if (response) {
            if (response.data.error === false) {
              this.error = false
              this.$refs.table.refresh()
            } else {
              console.log(response.data.message)
            }
          }
        })
    },
    nameValidate() {
      if (this.form.titles === undefined || this.form.titles.length === 0) {
        this.errorMessage = 'Поле "Наименование" обязательно для заполнения!<br/>'
        return false
      }

      this.errorMessage = null
      return true
    },
    idValidate() {
      if (this.form.id.trim() === '' || this.form.id === undefined) {
        this.errorMessage = 'Поле "ID" обязательно для заполнения!<br/>'
        return false
      }

      this.errorMessage = null
      return true
    },
    linkToUsers(promo){
      return promo.item.titles ? '<a href="/users?promoName=' + promo.item.registrationPromoName + '">' + promo.item.registrationCount + '</a>' : ''
    },
    addPromoName(newName) {
      this.promoNames.push(newName.trim())
      this.form.titles.push(newName.trim())
    },
    implodeTitles(row){
      return (row.titles && row.titles.length>0) ? row.titles.join(', ') : ''
    },
  },
}
</script>


<style scoped>
.header {
  color: #555;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 20px;
}

.text-throgh {
  text-decoration: line-through;
  color: #ccc;
}

.el-button {
  outline: none;
}

.error {
  color: #aaa;
}

.search {
  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none;
  border-color: grey;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
</style>

